const Faq = () => {
    return (
        <div className="main-faq">
            <h2>FAQ</h2>
            <ul>
                <li>
                    <a href="/support/faq?type=homePage">
                        홈페이지
                        <br />
                        회원<i className="i-member"></i>
                    </a>
                </li>
                <li>
                    <a href="/support/faq?type=receipt">
                        접수<i className="i-accept"></i>
                    </a>
                </li>
                <li>
                    <a href="/support/faq?type=application">
                        응시<i className="i-exam"></i>
                    </a>
                </li>
                <li>
                    <a href="/support/faq?type=cert">
                        자격제도<i className="i-license"></i>
                    </a>
                </li>
                <li>
                    <a href="/support/faq?type=etc">
                        기타<i className="i-etc"></i>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Faq;
