import { useEffect, useState } from 'react';
import MiniNotice from './MiniNotice';
import req from 'utils/restApi';

const MainBanner = () => {
    const [offsetWidth, setOffsetWidth] = useState(document.body.offsetWidth);
    const [bannerInfo, setBannerInfo] = useState([]);

    const handleResize = () => {
        setOffsetWidth(document.body.offsetWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        req('/api/com/bannerInfo', {}, (response) => setBannerInfo(response.data));
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="main-visual" style={{ background: 'none' }}>
            <h2 className="blind">메인 비주얼</h2>

            <div className="visual">
                <div className="swiper-wrapper">
                    {bannerInfo?.map((banner, index) => {
                        const bannerTag = (
                            <>
                                <img src={banner.imgSrc} alt="" width={offsetWidth} />
                                <span className="text" dangerouslySetInnerHTML={{ __html: banner.html }} />
                            </>
                        );
                        return (
                            <div className="swiper-slide" key={index}>
                                {banner.anchor != null ? <a href={banner.anchor}>{bannerTag}</a> : bannerTag}
                            </div>
                        );
                    })}
                </div>
                <div className="swiper-utility">
                    <button type="button" className="remote">
                        REMOTE
                    </button>
                    <button type="button" className="prev">
                        PREV
                    </button>
                    <div className="page"></div>
                    <button type="button" className="next">
                        NEXT
                    </button>
                </div>
            </div>
            <MiniNotice />
        </div>
    );
};

export default MainBanner;
