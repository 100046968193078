import { useLocation, useNavigate } from 'react-router-dom';
import { UseMenuGroupInfo } from '../../context';

const PageNavigation = () => {
    const location = useLocation();
    const menuGroup = UseMenuGroupInfo();
    const navigate = useNavigate();

    let parentMenuName = null;
    let childMenuName = null;
    if (menuGroup?.items) {
        for (let item of menuGroup.items) {
            for (let c of item.children) {
                if (c.url === location.pathname) {
                    childMenuName = c.title;
                    break;
                }
            }
            if (childMenuName != null) {
                parentMenuName = item.title;
                break;
            }
        }
    }

    const handleClick = () => {
        navigate('/');
    };

    return (
        <>
            {parentMenuName ? (
                <div className="location">
                    <span onClick={handleClick}>HOME</span>
                    <span>{parentMenuName}</span>
                    <span>{childMenuName}</span>
                </div>
            ) : null}
        </>
    );
};

export default PageNavigation;
