import { useCallback, useEffect, useState } from 'react';
import req from '../../utils/restApi';

import { StringUtil } from '../../utils/StringUtil';

const CoreServiceShortcuts = () => {
    const [exam, setExam] = useState(null);
    //현재 자격증이 벤처투자분석사 하나라서 합격자 발표에 고정해서 사용
    const [ventureCert, setVentureCert] = useState(null);

    useEffect(() => {
        if (exam === null) {
            reqExams();
        }
        if (ventureCert === null) {
            reqLastVentureCertExamInfo();
        }
    }, [exam, ventureCert]);

    const reqExams = useCallback(() => {
        req('/api/cert/exams', {}, (response) => setExamInfo(response.data));
    }, []);

    const reqLastVentureCertExamInfo = () => {
        req('/api/cert/lastCertExamInfo', { certId: 1 }, (response) => setVentureCert(response.data));
    };

    const setExamInfo = (list) => {
        if (list?.length > 0) {
            let newExam = {
                examNumber: '제' + list[0].examNumber + '회',
                examName: list[0].examName,
                examDesc: list[0].examDesc,
                examDate: '일시 : ' + StringUtil.getServerFormatToKrYMD(list[0].examDate),
                applyDate:
                    '접수 : ' +
                    StringUtil.getServerFormatToKrYMD(list[0].applyStartDate) +
                    ' ~ ' +
                    StringUtil.getServerFormatToKrYMD(list[0].applyEndDate)
            };
            setExam(newExam);
        }
    };

    let passShortcutName = ventureCert ? '제' + ventureCert.examNumber + '회 ' + ventureCert.examName : ' 합격자 조회 바로가기';

    return (
        <div className="main-cs">
            <h2 className="blind">고객센터</h2>
            <div className="row">
                <h3>시험 공고</h3>
                {exam === null ? (
                    <div className="complex">
                        <p className="tit">
                            진행중인
                            <br />
                            시험공고가 없습니다.
                            <br />
                            <span>(There are no test announcements.)</span>
                        </p>
                        <img className="art" src="../images/main/cs-img1.png" alt="" />
                    </div>
                ) : (
                    <div className="complex">
                        <p className="tit">
                            {exam.examNumber}
                            <br />
                            {exam.examName}
                            <br />
                            <span>{exam.examDesc}</span>
                        </p>
                        <ul className="txt">
                            <li>
                                <strong>{exam.examDate}</strong>
                            </li>
                            <li>{exam.applyDate}</li>
                        </ul>
                        <a href="/receipt/receiptApplication" className="more">
                            바로가기
                        </a>
                        <img className="art" src="../images/main/cs-img1.png" alt="" />
                    </div>
                )}
            </div>
            <div className="row">
                <h3>합격자 조회</h3>
                <div className="simple">
                    <p className="tit"> {passShortcutName} </p>
                    <a href="/pass/passer" className="more">
                        바로가기
                    </a>
                    <img className="art" src="../images/main/cs-img2.png" alt="" />
                </div>
                <h3>1:1 문의</h3>
                <div className="simple">
                    <p className="tit">1:1 문의 등록</p>
                    <a href="/support/oneonone" className="more">
                        바로가기
                    </a>
                    <img className="art" src="../images/main/cs-img3.png" alt="" />
                </div>
            </div>
        </div>
    );
};

export default CoreServiceShortcuts;
