import axios from 'axios';

const ax = axios.create();

ax.interceptors.request.use(
    (request) => {
        try {
            request.headers['Content-Type'] = 'application/json';
            console.log('request:', request.url, request.data, request.headers);
            return request;
        } catch (error) {
            console.error(error);
        }
        return request;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

ax.interceptors.response.use(
    (response) => {
        console.log('response', response.config.url, response);
        return response;
    },
    (error) => {
        console.error('error', error);
        return Promise.reject(error);
    }
);

const req = (
    path,
    params,
    success,
    fail = () => {},
    authInfo = { accessToken: '', accessTokenExpiresIn: 0 },
    dispatch = null,
    method = 'post'
) => {
    const r = method == 'post' ? ax.post : ax.get;
    console.log(authInfo.accessTokenExpiresIn - new Date().getTime(), authInfo.accessTokenExpiresIn, authInfo.accessToken);
    const headers = { headers: {} };
    if (authInfo.accessToken != '' && authInfo.accessToken !== 'undefined') {
        headers.headers = {
            ...headers.headers,
            Authorization: 'Bearer ' + authInfo.accessToken
        };
    }
    if (authInfo.accessToken != '' && authInfo.accessTokenExpiresIn < new Date().getTime()) {
        console.log(path, 'reissue');
        return ax
            .post('/api/auth/reissue', authInfo, headers)
            .then((response) => {
                dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
                console.log('reissue success, access token:', response.data.accessToken);
                headers.headers = {
                    ...headers.headers,
                    Authorization: 'Bearer ' + response.data.accessToken
                };
                return r(path, JSON.stringify(params), headers).then(success).catch(fail);
            })
            .catch((error) => {
                console.log(error, 'reissue failed');
                dispatch({ type: 'LOGOUT' });
                fail();
                window.location.href = '/';
            });
    } else {
        return r(path, JSON.stringify(params), headers).then(success).catch(fail);
    }
};

export default req;
