import MainBanner from './MainBanner';
import CoreServiceShortcuts from './CoreServiceShortcuts';
import Faq from './Faq';

const MainPage = () => {
    return (
        <main id="container">
            <MainBanner />
            <CoreServiceShortcuts />
            <Faq />
        </main>
    );
};

export default MainPage;
