import { useCallback, useEffect, useRef, useState } from 'react';
import req from '../../utils/restApi';
import MainNoticePopup from '../popup/MainNoticePopup';

const MiniNotice = () => {
    const [notices, setNotices] = useState(null);
    const [popupNotice, setPopupNotice] = useState(null);

    const refNoticeBody = useRef();

    useEffect(() => {
        if (notices == null) {
            reqNotice();
        }
    }, [notices]);

    useEffect(() => {
        setTimeout(() => {
            if (mainvisual) mainvisual.init();
        }, 1000);
    }, []);

    const reqNotice = useCallback(() => {
        req('/api/notice/all', {}, (response) => {
            if (response.data?.length > 0) {
                setList(response.data);
            } else {
                if (refNoticeBody.current) {
                    refNoticeBody.current.style.display = 'none';
                }
            }
        });
    }, []);

    const setList = (list) => {
        if (list) {
            let importantList = [];
            let popupNotice = null;
            for (let n of list) {
                if (n.important) {
                    importantList.push(n);
                }

                if (popupNotice === null && n.popup) {
                    popupNotice = n;
                }
            }
            setNotices(importantList);
            setPopupNotice(popupNotice);
        }
    };

    return (
        <div className="notice" ref={refNoticeBody}>
            <div className="swiper-wrapper">
                {notices?.map((notice, index) => (
                    <div className="swiper-slide" key={index}>
                        <a href="/support/notices">{notice.noticeTitle}</a>
                    </div>
                ))}
            </div>
            <button type="button" className="next">
                NEXT
            </button>
            <button type="button" className="prev">
                PREV
            </button>
            <MainNoticePopup notice={popupNotice} />
        </div>
    );
};

export default MiniNotice;
