import PropTypes from 'prop-types';
import { useState } from 'react';

const SelectPopup = ({ message, onClose, callback, btnOkName, btnCancelName }) => {
    const handleOnClickOk = () => {
        if (callback) callback('ok');
        onClose();
    };

    const handleOnClickCancel = () => {
        if (callback) callback('cancel');
        onClose();
    };

    return (
        <>
            <div id="modal">
                <div className="confirm1 active" data-modal-name="apply_complete">
                    <p style={{ whiteSpace: 'pre-line' }}>{message}</p>
                    <button type="button" className="btn-confirm" onClick={handleOnClickOk}>
                        {btnOkName}
                    </button>
                    <button type="button" className="btn-close" onClick={handleOnClickCancel}>
                        {btnCancelName}
                    </button>
                </div>
            </div>
        </>
    );
};

function useSelectPopup() {
    const [popup, setPopup] = useState(null);

    const openPopup = (message, callback, btnOkName = '확인', btnCancelName = '취소') => {
        setPopup({ message, callback, btnOkName, btnCancelName });
    };

    const closePopup = () => {
        setPopup(null);
    };

    const PopupComponent = popup ? (
        <SelectPopup
            message={popup.message}
            onClose={closePopup}
            callback={popup.callback}
            btnOkName={popup.btnOkName}
            btnCancelName={popup.btnCancelName}
        />
    ) : null;

    return [openPopup, PopupComponent];
}

SelectPopup.propTypes = {
    message: PropTypes.string,
    btnOkName: PropTypes.string,
    btnCancelName: PropTypes.string,
    onClose: PropTypes.func,
    callback: PropTypes.func
};

export default useSelectPopup;
