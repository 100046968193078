import { useNavigate } from 'react-router-dom';
import { UseAuthInfo, UseMenuGroupInfo } from '../../context.js';
import PropTypes from 'prop-types';
import useSelectPopup from '../popup/SelectPopup';
import { loginNeedPage } from '../../config.js';
import { useEffect, useState } from 'react';

const MainHeader = () => {
    const [authInfo, dispatch] = UseAuthInfo();
    const navigate = useNavigate();
    const menuGroup = UseMenuGroupInfo();
    const [menus, setMenus] = useState(null);
    const [openSelectPopup, SelectPopup] = useSelectPopup();

    useEffect(() => {
        if (menuGroup?.items && menus == null) {
            setMenus(menuGroup.items);
        }
    }, [menuGroup, menus]);

    let isLogin = authInfo?.userId && authInfo?.accessToken;

    const handleLogout = () => {
        dispatch({ type: 'LOGOUT' });
        navigate('/');
    };

    const handleMenuClick = (url) => {
        gnb.out();

        if (!isLogin) {
            if (loginNeedPage.includes(url)) {
                openSelectPopup('로그인이 필요한 페이지 입니다. 로그인 하시겠습니까?', (msg) => {
                    if (msg === 'ok') {
                        navigate('/login?url=' + url + '&page=login');
                    }
                });
                return;
            }
        }
        navigate(url);
    };

    return (
        <header id="header" className="main">
            <h1>
                <a href="/">
                    <img src="../images/common/logo1.png" alt="KVCA 한국벤처캐피탈협회" />
                    자격시험센터
                </a>
            </h1>
            <ul className="site">
                <li className="active">
                    <a href="/">자격시험센터</a>
                </li>
                <li>
                    <a href="https://ekvci.co.kr/">KVCA 이러닝센터</a>
                </li>
                <li>
                    <a href="https://edu.kvca.or.kr/">한국벤처캐피탈연수원</a>
                </li>
            </ul>
            <ul className="member">
                {/* <li style={{ cursor: 'pointer' }}>{isLogin ? <a href="/editMyInfo">내정보수정</a> : <a href="/register">회원가입</a>}</li> */}
                <li style={{ cursor: 'pointer' }}>{isLogin ? <a href="/editMyInfo">내정보수정</a> : <a></a>}</li>
                <li style={{ cursor: 'pointer' }}>{isLogin ? <a onClick={handleLogout}>로그아웃</a> : <a href="/login">로그인</a>}</li>
                <li style={{ cursor: 'pointer' }}>
                    <a href="/contactus">Contact Us</a>
                </li>
            </ul>
            <ul id="gnb">{<Menu items={menus} handleMenuClick={handleMenuClick} />}</ul>
            {SelectPopup}
        </header>
    );
};

const Menu = ({ items, handleMenuClick }) => {
    if (!items) return null;

    const handleMouseEnter = () => {
        gnb.in();
    };

    return (
        <>
            {items.map((item, index) => (
                <li onMouseEnter={handleMouseEnter} key={index}>
                    <a href="#">{item.title}</a>
                    <ul>
                        {item.children ? (
                            <>
                                {item.children.map((c, index) => (
                                    <li className={c.className} key={index}>
                                        <a style={{ cursor: 'pointer' }} onClick={() => handleMenuClick(c.url)}>
                                            {c.title}
                                        </a>
                                    </li>
                                ))}
                            </>
                        ) : null}
                    </ul>
                </li>
            ))}
        </>
    );
};

Menu.propTypes = {
    items: PropTypes.array,
    handleMenuClick: PropTypes.func
};

export default MainHeader;
