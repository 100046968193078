import { UseAuthInfo } from 'context';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const QuickService = () => {
    const [fold, setFold] = useState(false);
    const [authInfo] = UseAuthInfo();
    const navigate = useNavigate();

    const handleClickCertsPrint = () => {
        let isLogin = authInfo?.userId && authInfo?.accessToken;

        if (isLogin) {
            navigate('/pass/reqCert');
        } else {
            navigate('/login?url=/pass/reqCert');
        }
    };

    const handleClickPassResult = () => {
        let isLogin = authInfo?.userId && authInfo?.accessToken;

        if (isLogin) {
            navigate('/pass/passer');
        } else {
            navigate('/login?url=/pass/passer');
        }
    };

    return (
        <>
            {fold ? (
                <div className="quick">
                    <div className="quick_fold">
                        <button
                            type="button"
                            className="quick_fold_button"
                            onClick={() => {
                                setFold(false);
                            }}
                        >
                            위로
                        </button>
                    </div>
                </div>
            ) : (
                <div className="quick">
                    <h2>빠른 서비스</h2>
                    <button
                        type="button"
                        onClick={() => {
                            setFold(true);
                        }}
                    >
                        위로
                    </button>
                    <a onClick={handleClickCertsPrint} style={{ cursor: 'pointer' }}>
                        <i className="i-print1"></i>합격증
                        <br />
                        발급
                    </a>
                    <a onClick={handleClickPassResult} style={{ cursor: 'pointer' }}>
                        <i className="i-search1"></i>합격자
                        <br />
                        조회
                    </a>
                    <a href="/cert/intro?scroll=schedule">
                        <i className="i-schedule1"></i>시험
                        <br />
                        일정
                    </a>
                </div>
            )}
        </>
    );
};

export default QuickService;
