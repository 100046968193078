// project import
import React, { useEffect } from 'react';
import Routes from './routes';
import ScrollTop from './components/ScrollTop';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const isDev = process.env.REACT_APP_IS_DEV;

const App = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
        if (isMobile) {
            if (isDev === 'true') {
                window.location.href = 'http://121.131.15.236:3838/m/';
            } else {
                window.location.href = 'https://license.kvca.or.kr/m/';
            }
        }
    }, []);

    return (
        <ScrollTop>
            <Routes />
        </ScrollTop>
    );
};

export default App;
