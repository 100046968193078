import { useEffect, useState } from 'react';

const FRIEND_SITE_KVCA_E_LEARNING = 'KVCA 이러닝센터';
const FRIEND_SITE_KVCI = '한국벤처캐피탈연수원';

const MainFooter = () => {
    const [selectInit, setSelectInit] = useState(false);
    let className = window.location.href === '/' ? 'main' : '@@type';

    useEffect(() => {
        if (!selectInit) {
            setSelectInit(true);
            if (selectbox) selectbox.init('friend_site', handleChange);
        }
    }, [selectInit]);

    const handleChange = (e) => {
        console.log(e.target.value);
        if (e.target.value === FRIEND_SITE_KVCA_E_LEARNING) {
            window.location.href = 'https://ekvci.co.kr/';
        } else if (e.target.value === FRIEND_SITE_KVCI) {
            window.location.href = 'https://edu.kvca.or.kr/';
        }
    };

    return (
        <footer id="footer" className={className}>
            <div className="contents">
                <a href="#" className="logo">
                    KVCA한국벤처캐피탈협회
                </a>
                <ul className="policy">
                    <li>
                        <a href="/support/privacy">
                            <strong>개인정보처리방침</strong>
                        </a>
                    </li>
                    <li>
                        <a>이메일 무단수집거부</a>
                    </li>
                    <li>
                        <a href="/support/terms">이용약관</a>
                    </li>
                    <li>
                        <a href="/files/attach_cert_duty.pdf" download="붙임. 민간자격 표시의무사항.pdf">
                            민간자격 표시의무사항
                        </a>
                    </li>
                </ul>
                <select name="friend_site" style={{ width: '222px' }}>
                    <option>관련 사이트</option>
                    <option>{FRIEND_SITE_KVCA_E_LEARNING}</option>
                    <option>{FRIEND_SITE_KVCI}</option>
                </select>
            </div>
            <address className="address">
                <span>사업자번호 : 116-82-03540</span>
                <span>대표자 : 윤건수</span>
                <span>TEL : 02-3474-9231</span>
                <span>(06595)서울 서초구 서초대로45길 16 한국벤처투자빌딩 2층</span>
                <a href="mailto:edu@kvca.or.kr">E-mail: edu@kvca.or.kr</a>
                <span>FAX : 02-3474-9244</span>
            </address>
        </footer>
    );
};

export default MainFooter;
