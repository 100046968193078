import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const MainNoticePopup = ({ notice }) => {
    const [dayCheck, setDayCheck] = useState(null);
    const checkboxRef = useRef(null);

    useEffect(() => {
        if (dayCheck === null) {
            let isExpired = check24DayClose();
            setDayCheck(isExpired ? 'POPUP_OK' : 'POPUP_DENY');
        }
    }, [dayCheck]);

    const check24DayClose = () => {
        const expireDay = localStorage.getItem('Main24DayClose');
        let today = new Date();
        return today.getTime() > expireDay;
    };

    const handleOnClick = () => {
        modal.out();
        if (checkboxRef.current?.checked) {
            const tomorrowDate = new Date();
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            tomorrowDate.setHours(0, 0, 0, 0);
            localStorage.setItem('Main24DayClose', tomorrowDate.getTime());
        }
    };

    if (notice && dayCheck === 'POPUP_OK') {
        setDayCheck('POPUP_CHECK_DONE');
        setTimeout(() => {
            modal.in('main2');
        }, 100);
    }

    return (
        <>
            {' '}
            {notice ? (
                <>
                    <div id="dim"></div>
                    <div id="modal">
                        <div className="main mask2" data-modal-name="main2">
                            <h2 className="tit">{notice.noticeTitle}</h2>
                            <div className="html">
                                <p
                                    style={{ whiteSpace: 'pre' }}
                                    dangerouslySetInnerHTML={{ __html: notice ? notice.noticeContent : '' }}
                                ></p>
                            </div>
                            <div className="control">
                                <input ref={checkboxRef} type="checkbox" id="main_today" className="type2" />
                                <label htmlFor="main_today">오늘 하루 더 이상 보지 않음</label>
                                <button type="button" className="btn-close" onClick={handleOnClick}>
                                    닫기
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

MainNoticePopup.propTypes = {
    notice: PropTypes.object
};

export default MainNoticePopup;
